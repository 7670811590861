body {
  position: relative;
}

  .nav-link{
    color: #ffffff !important;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 6px 6px;
    text-shadow: 2px 2px 0 #4074b5, 2px -2px 0 #4074b5, -2px 2px 0 #4074b5, -2px -2px 0 #4074b5, 2px 0px 0 #4074b5, 0px 2px 0 #4074b5, -2px 0px 0 #4074b5, 0px -2px 0 #4074b5;
    
}

@media(max-width:768px){
  .nav-link{
    font-size: 1rem; 
  }

  }
  

.active{
  background-color: rgb(255,97,98) !important;
  border-radius: 10px;
}

.navbar-light .navbar-toggler{
   border-color: rgb(253,106,107) !important; ;
   background-color: rgb(48,55,238);
   color: #ffffff;
}

.fixed-top{
    transition: all .5s ease;
}

.bg-success.fixed-top{
    -webkit-box-shadow: 1px 8px 15px 1px rgba(255, 40, 2, 0.64);
-moz-box-shadow: 1px 8px 15px 1px rgba(255, 40, 2, 0.64);
box-shadow: 1px 8px 15px 1px rgba(255, 40, 2, 0.64);
}

.btn-success{
    background-color:#3ca8a2 !important; 
  }
.ubicacion{
  text-align: center;
}